import React, { useContext } from "react"
import $ from "jquery"
import classNames from "classnames"

// Contexts
import SearchContext from "../../../context/searchContext"

// Contansts
import { listHaulage, shippingCompanies } from "../../../constants"

const SectionScrollTransitTime = () => {
  const { resultSearchModal, handleChangeOrder, handleChangeHaulage } = useContext(SearchContext)
  const { data } = resultSearchModal
  const { originData, orderType, orderBy, typeShippingCompany, haulageFound, haulageType } = data

  const uniqueShippingCompanies = []
  const haulageDataCompanies = []

  shippingCompanies.map((item) => {
    Object.keys(originData).map((x) => {
      const origin = originData[x]

      if (x === item.id) {

        const newHaulageFound = haulageFound[item.id]
        
        if(newHaulageFound) {
          const haulageCount = []
          newHaulageFound.map((r, i) => {
            if(r.name === origin[i].tipo) {
              r.tamano = origin[i].result.length
              haulageCount.push(r)          
            }
          })
          
          haulageFound[item.id] = haulageCount
          uniqueShippingCompanies.push(item.name)
        }
      }
    })
  })

  if(typeShippingCompany !== 'ALL') {
      
    const sc = shippingCompanies.find((y) => y.name === typeShippingCompany)
    const haulageData = listHaulage[sc.id]

    haulageDataCompanies.push(...haulageData)
  }

  const handleScrollTable = (value) => {
    $(".js-pscroll").animate(
      {
        scrollLeft: `${value}px`,
      },
      "slow"
    )
  }

  return (
    <>
      <div className={classNames(haulageDataCompanies.length > 0 ? "col-lg-3" : "col-lg-4", "col-md-12", "col-12")}>
        <div className="back-buttons-chpa mb-2 mb-lg-0 justify-content-center justify-content-lg-start">
          <button type="button" className="btn btn-link scroll-btn" onClick={() => handleScrollTable("-=300")}>
            &#60; Shorter Transit Time
          </button>
        </div>
      </div>

      {typeShippingCompany !== 'ALL' && (
        <div className="col-lg-3 col-md-12 col-12 text-center">
          <select
            name="containerHaulage"
            className="form-control"
            onChange={(e) => {
              handleChangeHaulage(e.target.value)
            }}
            onBlur={() => {}}
            value={haulageType ? haulageType : ""}
          >
            {haulageDataCompanies.map(({ name, nameText, tamano }) => {
              return (
                <option value={name} key={name}>
                  {nameText}
                </option>
              )
            })}
          </select>
        </div>
      )}

      <div className={classNames(haulageDataCompanies.length > 0 ? "col-lg-3" : "col-lg-4", "col-md-12", "col-12", "text-center")}>
        <select
          name="containerType"
          className="form-control"
          onChange={(e) => handleChangeOrder(orderType, orderBy, e.target.value)}
          onBlur={() => {}}
          value={typeShippingCompany}
        >
          <option value="ALL" key="all">
            All
          </option>
          {uniqueShippingCompanies.map((unique) => {
            return (
              <option value={unique} key={unique}>
                {unique}
              </option>
            )
          })}
        </select>
      </div>

      <div className={classNames(haulageDataCompanies.length > 0 ? "col-lg-3" : "col-lg-4", "col-md-12", "col-12")}>
        <div className="next-buttons-chpa mt-2 mt-lg-0 justify-content-center justify-content-lg-end">
          <button type="button" className="btn btn-link scroll-btn" onClick={() => handleScrollTable("+=300")}>
            Longer Transit Time &#62;
          </button>
        </div>
      </div>
      <br />
      <br />
    </>
  )
}

export default SectionScrollTransitTime
