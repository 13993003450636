import React, { useState } from "react"

import SearchContext from "../../../context/searchContext"

// Context
import { formatDataShippingCompanies } from "../../../helpers/functionSearch"

// Components
import Form from "./Form"
import ResultSearch from "../ResultSearch"
import AdvancedSearch from "../AdvancedSearch"
import ResultNoSearch from "../ResultNoSearch"
import GoogleCaptchaWrapper from "../../GoogleCaptchaWrapper";

// Assets
import logo from "../../../images/LogoChalupaNo1.png"

const Main = () => {
  const [resultSearchModal, setResultSearchModal] = useState({
    isShow: false,
    isAdvancedSearch: false,
    data: {
      originData: {},
      dataSearch: {},
      orderType: "day",
      orderBy: "desc",
      typeShippingCompany: "ALL",
      data: [],
      rowShippingCompany: [],
      rowDaysString: [],
      rowVessel: [],
      rowVesselDepertureDate: [],
      rowDirect: [],
      rowOneTransshipment: [],
      rowTwoTransshipment: [],
      rowLoadingPorts: [],
      rowDischargePorts: [],
      haulageFound: [],
      haulageType: null,
    },
  })

  const [noResultSearchModal, setNoResultSearchModal] = useState({
    isShow: false,
    isAdvancedSearch: false,
    message: "",
  })

  const [isShowadvancedModal, setShowAdvancedModal] = useState(false)

  const handleChangeOrder = (orderType, orderBy, typeShippingCompany) => {
    const { data } = resultSearchModal
    const { originData, dataSearch, haulageType } = data

    const formatData = formatDataShippingCompanies({
      orderType,
      orderBy,
      haulageType,
      typeShippingCompany,
      dataShippingCompanies: originData,
      dataSearch: dataSearch,
    })

    setResultSearchModal((oldData) => ({
      ...oldData,
      data: {
        ...formatData,
        originData: originData,
        dataSearch: dataSearch,
        orderType,
        orderBy,
        typeShippingCompany,
      },
    }))
  }

  const handleChangeHaulage = (haulageType) => {
    const { data } = resultSearchModal
    const { originData, dataSearch,  orderType, orderBy, typeShippingCompany } = data

    const formatData = formatDataShippingCompanies({
      orderType,
      orderBy,
      haulageType,
      typeShippingCompany,
      dataShippingCompanies: originData,
      dataSearch: dataSearch,
    })

    setResultSearchModal((oldData) => ({
      ...oldData,
      data: {
        ...formatData,
        originData: originData,
        dataSearch: dataSearch,
        orderType,
        orderBy,
        typeShippingCompany,
      },
    }))
  }

  const handleCloseNoSearchModal = (type) => {
    if (type === "close") {
      const { isAdvancedSearch } = noResultSearchModal
      setNoResultSearchModal((oldData) => ({
        ...oldData,
        isAdvancedSearch: false,
        isShow: false,
      }))

      if (isAdvancedSearch) {
        setTimeout(() => {
          setShowAdvancedModal(true)
        }, 400)
      }
    }
  }

  return (
    <SearchContext.Provider
      value={{
        handleChangeOrder,
        setResultSearchModal,
        setShowAdvancedModal,
        setNoResultSearchModal,
        handleCloseNoSearchModal,
        handleChangeHaulage,
        resultSearchModal,
        isShowadvancedModal,
        noResultSearchModal,
      }}
    >
    <GoogleCaptchaWrapper>
      <section>
        <div className="banner-main-chpa">
          <div className="banner-img-main-chpa" style={{ display: "block" }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="logo-position-chp">
                    <img className="logo-chpa" src={logo} alt="Logo Chalupa" />
                  </div>
                </div>
                <div className="col-lg-4 col-md-3 col-4">
                  <div className="slogan-position-chpa">
                    <h2 className="title-slogan-chpa">
                      Navigating the <b>oceans</b> for the best routes
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-11">
                  <div className="form-search-wrap p-2" data-aos="fade-up" data-aos-delay="200">
                    <Form />
                  </div>
                </div>
              </div>
            </div>
            <div className="video-container container-fluid d-flex flex-row col-6 pt-5 align-items-start">
              <div className="row w-100">
                <div className="col-12 videoWrapper">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/BGnxdns8wX4?autoplay=1" allow="autoplay; encrypted-media" allowFullScreen>
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modals */}
        <ResultSearch />
        <AdvancedSearch />
        <ResultNoSearch />
      </GoogleCaptchaWrapper>

    </SearchContext.Provider>
  )
}

export default Main
